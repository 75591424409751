import React from "react";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { getUserInfo } from "../../../services/auth";
import {
  GetLocationsByCustomer,
  PreviewGSKData,
  UploadGSKData,
} from "../../../services/incidentReport";
import FormContainer from "../../atoms/formContainer";
import Hr from "../../atoms/hr";
import SubmitButton from "../../atoms/submitButton";

import {
  faBan,
  faCalendarAlt,
  faEnvelope,
  faExclamationCircle,
  faFile,
  faFileImport,
  faHome,
  faImage, faMapLocation,
  faPerson,
  faSignIn
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Center from "../../atoms/center";
import FormFieldType from "../../atoms/formFieldType";
import FormHeaderInfo from "../../atoms/formHeader";
import FormHeaderTitle from "../../atoms/formHeaderTitle";
import LargeButton from "../../atoms/largeButton";
import SelectMultiple from "../../atoms/selectMultiple";
import UserCard from "../../molecules/userCard";
import TableRowHeader from "../../atoms/tableRowHeader";
import TableRow from "../../atoms/tableRow";
import LocalDate from "../../atoms/localDate";
import PageMessage from "../../atoms/pageMessage";
import { navigate } from "gatsby";

/* eslint-disable */
const initialState = {
  ID: null,
  location: 0,
  allLocations: [],
  uploadFiles: [],
  preview: [],
  submitting: false,
  Submitter: "",
  isSSO: getUserInfo()?.SSO,
  CreatedBy: getUserInfo()?.Email,
};

export default class GSKImport extends React.Component {
  constructor(props) {
    super(props);

    this.child = React.createRef();
    this.state = initialState;

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    GetLocationsByCustomer("Gsk").then((res) => {
      if (res?.HasErrors) {
        res.ErrorList.forEach((item) => {
          toast.error(item);
        });
      } else {
        this.setState({ allLocations: res });
      }
    });
  };

  previewFile = () => {
    if (!this.state.uploadFiles) {
      return;
    }
    const file = this.state.uploadFiles[0];
    PreviewGSKData({
      location: this.state.location,
      email: this.state.CreatedBy,
      file: file,
    }).then((res) => {
      if (res.HasErrors) {
        res.ErrorList.forEach((item) => {
          toast.error(item);
        });
        this.setState({ preview: [], loading: false });
        return;
      }
      if (res.Errors) {
        res.Errors.forEach((item) => {
          toast.error(item);
        });
      }
      this.setState({ preview: res.Data });
    });
  };

  uploadFiles = async () => {
    debugger;
    const file = this.state.uploadFiles[0];
    this.setState({ uploadProgress: {}, uploading: true, uploadFiles: [] });
    const promises = [];

    promises.push(
      UploadGSKData({
        location: this.state.location,
        email: this.state.CreatedBy,
        file: file,
        formID: 1,
      })
    );

    try {
      const res = await Promise.all(promises);
      const result = res[0]?.data;
      if (result === true) {
        toast.success("Incidents imported successfully.");
        this.clear();
        return;
      }
      if (typeof result === "string") {
        toast.error(result);
        this.setState({ successfulUpload: false, uploading: false });
        return;
      }

      if (result.error) {
        toast.error("There was a server error processing the upload.");
        this.setState({ successfulUpload: false, uploading: false });
        return;
      }

      if (result.HasErrors) {
        result.ErrorList.forEach((item) => {
          toast.error(item);
        });
        this.setState({ successfulUpload: false, uploading: false });
      } else {
      }
    } catch (e) {
      console.log(e);
      this.setState({ successfulUpload: false, uploading: false });
      toast.error("Upload failed. Please check the format of your file.");
    }
  };

  updateUploadFiles = (files) => {
    this.setState({ uploadFiles: files });
  };

  // Handle Submit logic
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submitting: true }, () => {
      this.uploadFiles();
    });
  };

  openFileDialog = () => {
    if (this.props.disabled) {
      return;
    }
    this.fileInput.click();
  };

  onFilesAdded = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      this.setState({ uploadFiles: [files[0]] });
    }
  };

  clear = () => {
    var locations = this.state.allLocations;
    var location = this.state.location;
    this.setState({
      ...initialState,
      allLocations: locations,
      location: location,
    });
    if (typeof window !== "undefined") {
      document?.getElementsByTagName("form")[0].removeAttribute("class");
    }
  };

  changeLocation = (item) => {
    this.setState({
      location: item !== null ? item.value : 0,
    });
  };

  render() {
    const history = this.state.preview?.map((item, key) => (
      <TableRow key={key}>
        <Col>
          {item.Name} - {item.EmployeeNumber}
        </Col>
        <Col>{item.Email}</Col>
        <Col>
          <LocalDate>{item.IncidentDate}</LocalDate>
        </Col>
        <Col>{item.QLocationDesc}</Col>
        <Col xs="12">
          <Hr style={{ margin: "0.5rem 0" }}></Hr>
          <b>Description:</b>&nbsp;{item.QHowDidOccur}
        </Col>
      </TableRow>
    ));

    return (
      <>
        <Row>
          <Col>
            <FormContainer>
              <Row>
                <Col md="12" lg="12">
                  <FormHeaderTitle>
                    <FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon>
                    &nbsp;GSK&nbsp;
                    <FormFieldType lg={true}>Hazard ID</FormFieldType>
                    &nbsp;&nbsp;Import
                  </FormHeaderTitle>
                </Col>
              </Row>
              {!this.state.isSSO && (
                <>
                  <PageMessage style={{ color: "#b50303" }}>
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                    ></FontAwesomeIcon>
                    &nbsp; You must be logged in with SSO to import incidents.
                  </PageMessage>
                  <Center>
                    <LargeButton to="/forms/incident-report/">
                      <FontAwesomeIcon icon={faSignIn}></FontAwesomeIcon>
                      &nbsp;Login with SSO
                    </LargeButton>
                  </Center>
                </>
              )}

              {this.state.isSSO && (
                <>
                  <UserCard user={this.state.CreatedBy}></UserCard>
                  <form id="form" onSubmit={this.handleSubmit}>
                    <FormHeaderInfo>
                      <FontAwesomeIcon icon={faMapLocation}></FontAwesomeIcon>
                      &nbsp;Location
                    </FormHeaderInfo>

                    <SelectMultiple
                      placeholder={
                        "Find a GSK Location Address, City, or Country"
                      }
                      onChange={this.changeLocation}
                      single={true}
                      largeStyles={true}
                      options={this.state.allLocations}
                      disabled={false}
                      value={this.state.allLocations?.find(
                        (z) => z.value === this.state.location
                      )}
                    />
                    <Hr />

                    <FormHeaderInfo>
                      <FontAwesomeIcon icon={faImage}></FontAwesomeIcon>
                      &nbsp;GSK Excel Template
                    </FormHeaderInfo>
                    {!this.state.submitting && (
                      <Row>
                        <Col xs="12">
                          <Center>
                            <LargeButton
                              onClick={this.openFileDialog}
                              altColor={true}
                            >
                              <input
                                type="file"
                                multiple={false}
                                ref={(input) => (this.fileInput = input)}
                                onChange={(event) => {
                                  this.onFilesAdded(event);
                                }}
                                style={{ display: "none" }}
                              />
                              <FontAwesomeIcon
                                icon={faFileImport}
                              ></FontAwesomeIcon>
                              &nbsp;&nbsp;Click to Upload File
                            </LargeButton>
                            <br />
                            {this.state.uploadFiles.length > 0 && (
                              <b>
                                <FontAwesomeIcon
                                  icon={faFile}
                                ></FontAwesomeIcon>
                                &nbsp;
                                {this.state.uploadFiles[0]?.name}
                              </b>
                            )}
                          </Center>
                        </Col>

                        <Col></Col>
                      </Row>
                    )}
                    <Hr />
                    <Row>
                      <Col>
                        <Center>
                          <LargeButton
                            large={true}
                            disabled={
                              this.state.location === 0 ||
                              this.state.uploadFiles.length === 0
                            }
                            submitting={this.state.submitting}
                            onClick={this.previewFile}
                          >
                            <FontAwesomeIcon
                              icon={faFileImport}
                            ></FontAwesomeIcon>
                            &nbsp; Preview Imported Incidents
                          </LargeButton>
                        </Center>
                      </Col>
                      <Col>
                        <Center>
                          <LargeButton
                            large={true}
                            submitting={this.state.submitting}
                            onClick={() => {
                              navigate("/forms/incident-report/");
                            }}
                          >
                            <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
                            &nbsp;Back to Home
                          </LargeButton>
                        </Center>
                      </Col>
                    </Row>
                    {this.state.preview.length > 0 && (
                      <>
                        <TableRowHeader bgColor={"#003ba8"}>
                          <Col>
                            <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
                            &nbsp;Name
                          </Col>
                          <Col>
                            <FontAwesomeIcon
                              icon={faEnvelope}
                            ></FontAwesomeIcon>
                            &nbsp;Email
                          </Col>
                          <Col>
                            <FontAwesomeIcon
                              icon={faCalendarAlt}
                            ></FontAwesomeIcon>
                            &nbsp;Date
                          </Col>
                          <Col>
                            <FontAwesomeIcon
                              icon={faMapLocation}
                            ></FontAwesomeIcon>
                            &nbsp;Location
                          </Col>
                        </TableRowHeader>
                        {this.state.loading && (
                          <Row>
                            <Loading size="lg"></Loading>
                          </Row>
                        )}
                        <Row className="stripe">
                          <Col>{history}</Col>
                        </Row>
                        <Hr />

                        <Row>
                          <Col>
                            <Center>
                              <LargeButton
                                large={true}
                                altColor={true}
                                onClick={this.clear}
                              >
                                <FontAwesomeIcon icon={faBan}></FontAwesomeIcon>
                                &nbsp; Clear All Data
                              </LargeButton>
                            </Center>
                          </Col>

                          <Col>
                            <Center>
                              <SubmitButton
                                large={true}
                                disabled={
                                  this.state.location === 0 ||
                                  this.state.uploadFiles.length === 0
                                }
                                text={
                                  "Import " +
                                  this.state.preview.length +
                                  "x Incidents"
                                }
                                submitting={this.state.submitting}
                              ></SubmitButton>
                            </Center>
                          </Col>
                        </Row>
                      </>
                    )}
                  </form>{" "}
                </>
              )}
            </FormContainer>
          </Col>
        </Row>
      </>
    );
  }
}
